.global-spinner {
  background: rgba(255, 255, 255, .8);

  position: fixed;
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9999;
}
