#register-template {
  margin: 0 auto;
  width: 50%;

  h1 {
    margin-top: 100px;
  }

  form {
    padding-top: 1rem;
    border-top: 1px solid rgb(238, 238, 238);
    text-align: right;

    label {
      width: 100%;
      text-align: left;

      input[type=email],
      input[type=password] {
        display: block;
        width: 100%;
      }

      input[disabled] {
        background: #EFEFEF;
      }
    }

    button {
      padding-left: .75rem;
      padding-right: .75rem;
    }

    .errors {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: left;
      color: red;
    }
  }
}
