.form-components-eligibilities {
  .ember-power-select-trigger {
    // width: 68% !important;
  }

  .eligibility-table {
    border: 0;
    margin-top: 0rem !important;
  }

  .btn {
    padding: 0 0.40rem;
    font-size: 0.9rem;
  }
}
