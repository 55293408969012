.metrics-widget-side-drawer {
  .side-drawer-body {
    padding: 10px !important;
  }

  .metric-drawer-section-subheader {
    font-size: 13px;
    font-weight: 600;
    margin: 15px 0px 5px 0px;
  }

  .metric-drawer-section-subheader:first-of-type {
    margin: 5px 0px;
  }

  .ember-power-select-trigger {
    margin-left: 0px !important;
    width: 100% !important;
  }

  .filter-settings-table {
    border: none;
  }

  .metric-filter-section {
    display: flex;
  }

  .metric-filter-input-cont {
    flex: 1;
    padding: 0px 5px;
    font-size: 13px;
  }

  .metric-filter-input-cont:first-of-type {
    padding-left: 0px;
  }

  .metric-filter-input-cont:last-of-type {
    padding-right: 0px;
  }

  .side-drawer-checkbox {
    label {
      font-size: 13px;
    }
  }
}
