.edit-sidebar {
  background-color: #eee;
  height: 100%;
  width: 275px;

  border-right: 1px solid #ccc;

  td {
    width: 150px;
  }
}
