// these need to be imported before bootstrap
// ref: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

$primary: #7CB342;

input.form-control {
    height: 23px;
    text-transform: uppercase;
    padding: 0 5px;
}
