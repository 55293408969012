.dashboard-picker {
  .dashboard-picker-section {}

  .dashboard-picker-section-title {
    font-weight: bold;
    font-size: 0.8em;
    background-color: #eee;

    padding: 0 5px;
  }

  .dashboard-picker-item {
    display: flex;
    align-items: center;

    padding: 10px;
    border-bottom: 1px solid #eee;

    &.is-open.is-clean {
      color: green;
    }

    .spacer {
      flex: 1 0 1px;
    }

    button {
      justify-self: flex-end;
    }
  }
}
