.dashboard-wrapper {
  display: flex;
  flex-direction: column;

  height: 100%;

  .timeline-picker-wrapper {
    flex: 0 0 auto;
  }

  .tile-dashboard-wrapper {
    flex: 1 1 0;

    overflow-y: auto;
    overflow-x: hidden;

    background-color: $grid-background;
  }
}
