.form-components-multi-select {

  td.cell-label.table-label {
    font-weight: 500;
    font-size: 16px;
    height: 23px;
    text-align: left;
  }
  .multi-select-cell-label {
    font-size: $widget-input-font-size;
    line-height: 1em;
    padding: 1px 0 1px 10px;
    text-align: left;
    vertical-align: middle;
    min-width: 105px;
  }

  .multi-select-table {
    border: 0;
    margin-top: 0rem !important;

    td.cell-label {
      font-size: $widget-input-font-size;
      line-height: 1em;
      padding: 1px 0 1px 10px;
      text-align: left;
      vertical-align: middle;
      min-width: 105px;

    }

    .edit-cell {

    }
  }

  td.button-cell {
  }

  .btn {
    padding: 0 0.40rem;
    font-size: 0.9rem;
  }
}
