
.bar-chart-widget {
  .bar-chart-widget-body {
      .bar-chart-container {
        position: relative;
        height: 100%;
        width: 100%;
      }

  }
}
