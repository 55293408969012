
.minimize-button {
  padding-left: 7px !important;
  .fa-times {
    position: relative;
    top: -1px;
  }
}

.maximized-widget-content {
  height: 100%;

  .dashboard-widget {
    box-shadow: none;
  }
  .column-widget {
    height: 100%;
    .column-widget-body {
      // must set `flex-basis` or else light table will have incorrect height
      flex-basis: auto;
      width: calc(100% - #{$widget-border-width});
      overflow: auto;
  
      font-size: $widget-body-font-size;
      background-color: $table-background-color;
  
      .ember-light-table {
      overflow: visible;
  
        .lt-head-wrap {
          background-color: $table-column-header-background-color;
          /* box-shadow: 0px 0px 5px 0px $grid-color; */
          box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
          z-index: 1;
          overflow: visible;
          position: sticky;
          top: 0;
        }
  
        .lt-head {
          width: 100%;
          border-left: solid $table-header-left-border-width rgba(255,255,255, .0);
  
          th {
            padding: 0 4px 0 1px;
            th {
              padding: 4px;
            }
            font-weight: 700;
            color: $text;
            opacity: 0.8;
  
  
            &:first-child {
              padding-left: 2px;
            }
          }
  
          // draw line for resize handle
          .lt-column-resizer {
            background: linear-gradient(to right,
                                        transparent 0%,
                                        transparent calc(50% - 1px),
                                        #ccc calc(50% - 1px),
                                        #ccc calc(50% + 0px),
                                        transparent calc(50% + 0px),
                                        transparent 100%) !important;
                                        height: 70%;
                                        margin-top: 4px;
  
          }
        }
  
        .lt-column {
          &:focus-within {
            background-color: $table-cell-selected-color;
          }
        }
  
        .otp-danger .highlightable {
          color: $otp-danger;
        }
  
        .otp-warning .highlightable {
          color: $otp-warning;
        }
  
        .lt-row {
          border-left: solid $table-row-left-border-width rgba(255,255,255, .0);
  
          &:nth-child(2n-1) {
            background-color: $table-row-alt-background-color;
          }
  
          &.is-selected {
            background-color: $table-row-selected-color !important;
          }
  
          &.lt-is-loading {
            text-align: center;
            background-color: transparent;
          }
  
          &.lt-no-data {
            background-color: transparent;
          }
  
          // Status Styles
          // &.late { background: $table-row-late-color; }
          // &.pending { background: $table-row-pending-color; }
          // &.ahead { background: $table-row-ahead-color; }
  
          &.drag-target-above {
            border-top: 15px solid #ccf;
          }
  
          &.drag-target-below {
            border-bottom: 15px solid #ccf;
          }
  
          &.drag-target {
            background-color: #ccf;
          }
  
          &.otp-warning {
            border-color: $otp-warning;
          }
  
          &.otp-danger {
            border-color: $otp-danger;
          }
        }
  
        .lt-cell {
          padding: 0 4px 1px 1px; // same here: we just want the top cell to have padding
  
          &:focus-within {
            background-color: $table-cell-selected-color;
          }
  
          .lt-cell {
            padding: 0 0 0 0;
          }
        }
  
        .lt-body-wrap {
          margin-top: 3px;
        }
  
        .lt-body {
          color: $table-body-text;
          font-weight: 500;
          margin-top: 3px;
        }
  
        .is-sorted {
          .lt-sort-icon {
            visibility: visible;
          }
        }
  
        .lt-sort-icon {
          float: none;
          margin-left: auto;
          margin-right: 0;
          padding-left: 2px;
          // visibility: hidden;
          line-height: 20px;
        }
      }
  
      .column-widget-editable-cell {
        width: 100%;
      }
  
      .cell-text-extension {
         background-color: transparent;
         border: 0;
         padding: 0;
         color: #657175;
         font-weight: 300;
         text-overflow: ellipsis;
         white-space: nowrap;
      }
    }
  }

  .map-widget {
    height: 100%;
    .map-widget-body {
      height: inherit;
      z-index: 1;
    }
  }
}
