// colors
$primary-button: #7CB342; // links and buttons
$alt-primary-color: #689F38;

$header-dark-background: #263238;
$header-light-background: #eee;
$text: #303E44;

$grey: #F0F2F3;
$dds-green1: #7CB342; /* rgb(124, 179, 66) */
$dds-green2: #689F38; /* rgb(104, 159, 56) */
$dds-grey1: #303E44; /* rgb(38, 50, 56) */
$dds-grey2: #263238; /* rgb(38, 50, 56) */

$danger-color: red;

// OTP colors
$otp-ontime: #555e63;
$otp-danger: red;
$otp-warning: orange;

// Zone Stats colors
$zone-danger: red;
$zone-warning:orange;
$zone-normal: blue;
$zone-good: green;

// Stand Stats colors
$stand-danger: red;
$stand-warning:orange;
$stand-normal: green;
$stand-good: blue;  

$shape-danger: red;
$shape-warning:orange;
$shape-normal: green;
$shape-good: blue;  


// Trip Status colors
$tripstatus-booked: #CD42BC;
$tripstatus-accepted: #00A6ED;
$tripstatus-accept: #00A6ED;
$tripstatus-arrive: #50E3C2;
$tripstatus-work: #304DFD;
$tripstatus-depart: #304DFD;
$tripstatus-inservice: #304DFD;
$tripstatus-load: #304DFD;
$tripstatus-arrived: #50E3C2;
$tripstatus-completed: #8B572A;
$tripstatus-noshow: #8B572A;
$tripstatus-underdispatch: #8755FF;
$tripstatus-active: #8755FF;
$tripstatus-offered: #04BE6E;
$tripstatus-cancelled: #000000;
$tripstatus-onhold: #000000;
$tripstatus-scrub: #000000;
$tripstatus-altered: #000000;

// Vehicle Status colors
$vehiclestatus-booked: #CD42BC;
$vehiclestatus-onbreak: #CD42BC;
$vehiclestatus-accepted:#00A6ED;
$vehiclestatus-at-work:#00A6ED;
$vehiclestatus-load: #304DFD;
$vehiclestatus-arrived: #50E3C2;
$vehiclestatus-STC: #50E3C2;
$vehiclestatus-occupied: #50E3C2;
$vehiclestatus-completed: #8B572A;
$vehiclestatus-free: #8B572A;
$vehiclestatus-underdispatch: #8755FF;
$vehiclestatus-offered: #04BE6E;
$vehiclestatus-tempoff: #000000;
$vehiclestatus-onhold: #000000;
$vehiclestatus-off: #000000;
$vehiclestatus-empty: #000000;
$vehiclestatus-emergency: red;
$vehiclestatus-checking: #0e0b0b;
$vehiclestatus-unexplained: #000000;


// Driver Status colors
$driverstatus-working: #00A6ED;
$driverstatus-signoff: #000000;
$driverstatus-onbreak: #CD42BC;
$driverstatus-tempoff: #000000;
$driverstatus-active:#00A6ED;
$driverstatus-onhold: #000000;
$driverstatus-off: #000000;
$driverstatus-empty: #000000;
$driverstatus-emergency: red;
$driverstatus-checking: #000000;
$driverstatus-unexplained: #000000;


// widget colors
$widget-shadow-color: #b3b3b3;
$widget-outline-color: #303E44;
$widget-drag-handle-color: #b7b7b7;
$widget-dropdown-shadow: #7d7a7a;

// button colors
$widget-button-color: #9DABB0;
$widget-button-focused-color: $primary-button;
$button-outline: $widget-shadow-color;
$button-highilighted-color: #797979;
$button-disabled-background: rgba(0,0,0,.1);


$icon-light-color: #9DABB0;
$icon-light-highlighted-color: $text;
$icon-dark-color: white;
$icon-dark-highlighted-color: $primary-button;

// side-drawer variables
$sidedrawer-background-color: white;

$sidedrawer-table-background-color: #F5F5F7;
$sidedrawer-table-border-color: #CCCED0;

$sidedrawer-header-icon-color: #A9ADB0;
$sidedrawer-font-color: #303E44;
$sidedrawer-input-outline-color: #D4D6D7;
$sidedrawer-input-font-color: #334147;
$sidedrawer-button-color: #263238;
$sidedrawer-button-outline: $sidedrawer-button-color;

$sidedrawer-title-font-size: 15px;

// form side-drawer colors
$form-widget-button-color: #AAADB0;
$form-widget-button-disabled-color: rgb(218, 218, 218);

// default css button variables
$primary: $primary-button;
$secondary: $icon-light-color;

// grid colors
$grid-background: #e6e7eb;
$grid-cell-color: #e0e1e4;
/* $grid-background: #e3e7e8;
$grid-cell-color: #d8d8d8; */
$grid-gutter-color: $grid-background;

// table colors
$table-body-text: #263238;
$table-background-color: #fff;
/* $table-body-text: #657175;
$table-background-color: #FAF9FA; */

/* $table-header-background-color: $grey;
$table-row-alt-background-color: #ebecf1; */
$table-header-background-color: white;
$table-column-header-background-color: rgb(245, 245, 247);

$table-row-alt-background-color: rgba(230, 231, 235, .4);

$table-row-late-color: #f2dede;
$table-row-pending-color: #fcf8e3;
$table-row-ahead-color: #dff0d8;
$table-row-selected-color: #7cb34245;
$table-row-checked-color: #883b34;
$table-row-left-border-width: 5px;
$table-header-left-border-width: 3px;
$table-cell-selected-color: #5d863145;

// fonts
$ui-font: "AvenirNext";
$widget-title-font-size: 13px;
$widget-input-font-size: 16px;
$widget-body-font-size: 16px;
$widget-button-font-size: 16px;

// maximum supported browser width in pixels
$max-dashboard-width: 5000;
$max-dashboard-height: 2000;

// tile configuration options
$tile-sizes: 100, 150, 2000;
$tile-spacings: 2, 4, 6, 8, 16;

// grid configuration
$grid-color: #ddd;

// widget configuration
$widget-border-width: 0px;
$widget-header-height: 32px;
$widget-header-padding: 2px;

$column-title-margin: 4px;

$nav-height: 33px;

$timeline-date-picker-width: 250; // must be 7x + 12, e.g. 292, 299, 306, etc.
$timeline-time-picker-width: 90; // TBD
