.btn {
  &.btn-outline-primary-simple {
    color: $alt-primary-color;
    border-color: $alt-primary-color;
    &:hover {
      background-color: $alt-primary-color;
      color: white;
    }
    &:focus {
      box-shadow: none;
    }

    &:active {
      background-color: $alt-primary-color;
      color: white;
    }
  }

  &.btn-primary-simple {
    color: white;
    background-color: $primary-button;
    border-color: $primary-button;
    &:hover {
      background-color: $alt-primary-color;
    }

    &:focus {
      box-shadow: none;
    }

    &:active {
      background-color: $alt-primary-color;
    }
  }
}
