.cell-text-content-extension {
  color: #657175;
  font-weight: 300;
  margin-right: 10px;
  margin-left: 10px;

  .header {
    font-weight: 600;
    margin-bottom: 5px;
  }
}
