.form-components-places {

  .places-table {
    border: none;
    margin-top: 0rem !important;

    span.ember-power-select-selected-item {
      margin-left: 2px;
    }

  }

  .places-inline-button {
    width: 20px;
    height: 20px;
    // margin: auto;
    padding: 0 3px 3px 3px;
    font-size: $widget-button-font-size;

  }

}

.custom-cursor {
  cursor: url(/location-26.png) 12 25, pointer !important;
}

.map-btn {
  padding: 0px 10px;
}
