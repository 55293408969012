.form-components-multi-select {

  td.cell-label.table-label {
    font-weight: 500;
    font-size: 16px;
    height: 23px;
    text-align: left;
  }

  .radio-table {
    border: 0;
    margin-top: 0rem !important;

    td.cell-label {
      

    }

    .edit-cell {

    }
    
  }

  td.button-cell {
  }

  .btn {
    padding: 0 0.40rem;
    font-size: 0.9rem;
  }
}
