.table-text-extension-cell {
  .ember-basic-dropdown-trigger:focus {
    outline: none;
  }

  .cell-text-content-extension {
    label.mainItem {
      font-weight: 600;
      margin-top: 1px;
      margin-bottom: 3px;
      font-size: $widget-title-font-size;
    }

    label.valueItem {
      font-size: $widget-body-font-size;
      margin-bottom: 3px;
    }
  }

  .cell-dropdown-content {
    padding: 2px 6px 2px 6px;
    box-shadow: 0px 0px 2px 0px $widget-dropdown-shadow;
    font-family: $ui-font;
    font-size: $widget-body-font-size;
    background-color: white;
    color: black;
  }
}

