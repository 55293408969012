.route-activity-log {
  height: 100%;

  display: flex;
  flex-direction: column;

  .side-drawer-body {
    padding-left: 10px;
    padding-right: 10px;

    font-size: 16px;

    .ember-light-table {
      flex: 1 0 100px;
    }

    .lt-row {
      &:nth-child(2n-1) {
        background-color: $table-row-alt-background-color;
      }

      &.is-selected {
        background-color: $table-row-selected-color !important;
      }
    }

    .lt-cell {
      vertical-align: top;
    }
  }
}
