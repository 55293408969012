.column-widget {

  display: flex;
  flex-direction: column;
  padding: $widget-border-width 0 $widget-border-width $widget-border-width;
  color: $text;
  font-family: $ui-font;

  &.stops-widget.is-reordering {
    display: flex;
    flex-direction: column;
    border-width: 3px;
    border-style: solid;
    border-color: #2874CB;
  }

  .column-widget-header {
    flex-basis: $widget-header-height;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $widget-header-padding;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;

    background-color: $table-header-background-color;

    .column-widget-title {
      flex-grow: 1;
      flex-shrink: 0;
      line-height: $widget-header-height - (2 * $widget-header-padding);
      margin: 0 0 0 $column-title-margin;
      padding-left: 10px;
      font-weight: 600;
      font-size: $widget-title-font-size;

      i {
        margin-left: 5px;
      }
    }

    // the search box needs a little extra margin for focus border
    .column-widget-search-box {
      flex-grow: 1;
      flex-shrink: 0;
      height: $widget-header-height - (2 * $widget-header-padding) - 2px;
      margin: 1px 2px 1px $column-title-margin;
      padding: 3px 5px 3px 5px;
      max-width: 20em;
      border-radius: 5px;
      font-size: $widget-input-font-size;
      background-color: transparent;
      border: none;
      outline: none;
    }

    .column-widget-controls {
      flex-shrink: 0;
      height: 26px;
      margin-right: $column-title-margin;
      margin-left: auto; // helpful if we need to change .column-widget-header's justify-content attribute
      cursor: default;

      .column-widget-button {
        margin-top: 2px;
        padding: 1px 8px 0 8px;
        display: inline-block;
        background-color: inherit;
        border: none;
        color: $icon-light-color;
        opacity: 0.5;
        cursor: pointer;
        outline: none;

        height: 25px;

        &:focus {
          box-shadow: 0 0 1px 1px $table-cell-selected-color;
          border-radius: 4px;
          opacity: 1;;
        }

        &:hover {
          color: $alt-primary-color;
          transition: color 0.2s;
          opacity: 1;
        }
      }
      .active-button {
        color: $icon-light-highlighted-color;
        transition: color 0.2s;
      }
    }
  }

  .column-widget-body {
    // must set `flex-basis` or else light table will have incorrect height
    flex-basis: 300px;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - #{$widget-border-width});
    overflow: auto;

    font-size: $widget-body-font-size;
    background-color: $table-background-color;

    .ember-light-table {
    overflow: visible;

      .lt-head-wrap {
        background-color: $table-column-header-background-color;
        /* box-shadow: 0px 0px 5px 0px $grid-color; */
        box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
        z-index: 1;
        overflow: visible;
        position: sticky;
        top: 0;
      }

      .lt-head {
        width: 100%;
        border-left: solid $table-header-left-border-width rgba(255,255,255, .0);

        th {
          padding: 0 4px 0 1px;
          th {
            padding: 4px;
          }
          font-weight: 700;
          color: $text;
          opacity: 0.8;


          &:first-child {
            padding-left: 2px;
          }
        }

        // draw line for resize handle
        .lt-column-resizer {
          background: linear-gradient(to right,
                                      transparent 0%,
                                      transparent calc(50% - 1px),
                                      #ccc calc(50% - 1px),
                                      #ccc calc(50% + 0px),
                                      transparent calc(50% + 0px),
                                      transparent 100%) !important;
                                      height: 70%;
                                      margin-top: 4px;

        }
      }

      .lt-column {
        &:focus-within {
          background-color: $table-cell-selected-color;
        }
      }

      .otp-danger .highlightable {
        color: $otp-danger;
      }

      .otp-warning .highlightable {
        color: $otp-warning;
      }

      .zone-danger .highlightable {
        color: $zone-danger;
      }
      .zone-warning .highlightable {
        color: $zone-warning;
      }
      .zone-normal .highlightable {
        color: $zone-normal;
      }
      .zone-good .highlightable {
        color: $zone-good;
      }

      .stand-danger .highlightable {
        color: $stand-danger;
      }
      .stand-warning .highlightable {
        color: $stand-warning;
      }
      .stand-normal .highlightable {
        color: $stand-normal;
      }
      .stand-good .highlightable {
        color: $stand-good;
      }

      .shape-danger .highlightable {
        color: $stand-danger;
      }
      .shape-warning .highlightable {
        color: $stand-warning;
      }
      .shape-normal .highlightable {
        color: $stand-normal;
      }
      .shape-good .highlightable {
        color: $stand-good;
      }

      .tripstatus-booked .highlightable {
        color: $tripstatus-booked;
      }
      .tripstatus-accepted .highlightable {
        color: $tripstatus-accepted;
      }
      .tripstatus-accept .highlightable {
        color: $tripstatus-accept;
      }
      .tripstatus-active .highlightable {
        color: $tripstatus-active;
      }
      .tripstatus-arrive .highlightable {
        color: $tripstatus-arrive;
      }
      .tripstatus-load .highlightable {
        color: $tripstatus-load;
      }
      .tripstatus-completed .highlightable {
        color: $tripstatus-completed;
      }
      .tripstatus-noshow .highlightable {
        color: $tripstatus-noshow;
      }
      .tripstatus-arrived .highlightable {
        color: $tripstatus-arrived;
      }
      .tripstatus-underdispatch .highlightable {
        color: $tripstatus-underdispatch;
      }
      .tripstatus-offered .highlightable {
        color: $tripstatus-offered;
      }
      .tripstatus-cancelled .highlightable {
        color: $tripstatus-cancelled;
      }
      .tripstatus-onhold .highlightable {
        color: $tripstatus-onhold;
      }
      .tripstatus-altered .highlightable {
        color: $tripstatus-altered;
      }
      .tripstatus-scrub .highlightable {
        color: $tripstatus-scrub;
      }
      .tripstatus-inservice .highlightable {
        color: $tripstatus-inservice;
      }
      .vehiclestatus-accepted .highlightable {
        color: $vehiclestatus-accepted;
      }
      .vehiclestatus-occupied .highlightable {
        color: $vehiclestatus-occupied;
      }
      .vehiclestatus-load .highlightable {
        color: $vehiclestatus-load;
      }
      .vehiclestatus-completed .highlightable {
        color: $vehiclestatus-completed;
      }
      .vehiclestatus-arrived .highlightable {
        color: $vehiclestatus-arrived;
      }
      .vehiclestatus-empty .highlightable {
        color: $vehiclestatus-empty;
      }
      .vehiclestatus-underdispatch .highlightable {
        color: $vehiclestatus-underdispatch;
      }
      .vehiclestatus-offered .highlightable {
        color: $vehiclestatus-offered;
      }
      .vehiclestatus-onbreak .highlightable {
        color: $vehiclestatus-onbreak;
      }
      .vehiclestatus-onhold .highlightable {
        color: $vehiclestatus-onhold;
      }
      .vehiclestatus-unexplained .highlightable {
        color: $vehiclestatus-unexplained;
      }
      .vehiclestatus-emergency .highlightable {
        color: $vehiclestatus-emergency;
      }
      .vehiclestatus-STC .highlightable {
        color: $vehiclestatus-STC;
      }
      .vehiclestatus-tempoff .highlightable {
        color: $vehiclestatus-tempoff;
      }
      .vehiclestatus-checking .highlightable {
        color: $vehiclestatus-checking;
      }     
      .vehiclestatus-free .highlightable {
        color: $vehiclestatus-free;
      }
      .driverstatus-working .highlightable {
        color: $driverstatus-working;
      }
      .driverstatus-signoff .highlightable {
        color: $driverstatus-signoff;
      }
      .driverstatus-onbreak .highlightable {
        color: $driverstatus-onbreak;
      }
      .driverstatus-tempoff .highlightable {
        color: $driverstatus-tempoff;
      }
      .driverstatus-active .highlightable {
        color: $driverstatus-active;
      }
      .driverstatus-onhold .highlightable {
        color: $driverstatus-onhold;
      }
      .driverstatus-off .highlightable {
        color: $driverstatus-off;
      }
      .driverstatus-empty .highlightable {
        color: $driverstatus-empty;
      }
      .driverstatus-emergency .highlightable {
        color: $driverstatus-emergency;
      }
      .driverstatus-checking .highlightable {
        color: $driverstatus-checking;
      }
      .driverstatus-unexplained .highlightable {
        color: $driverstatus-unexplained;
      }


      .lt-row {
        border-left: solid $table-row-left-border-width rgba(255,255,255, .0);

        &:nth-child(2n-1) {
          background-color: $table-row-alt-background-color;
        }

        &.is-selected {
          background-color: $table-row-selected-color !important;
        }

        &.is-last-checked {
          background-color: $table-row-checked-color !important;
        }

        &.is-checked {
          background-color: $table-row-checked-color !important;
        }

        &.lt-is-loading {
          text-align: center;
          background-color: transparent;
        }

        &.lt-no-data {
          background-color: transparent;
        }

        // Status Styles
        // &.late { background: $table-row-late-color; }
        // &.pending { background: $table-row-pending-color; }
        // &.ahead { background: $table-row-ahead-color; }

        &.drag-target-above {
          border-top: 15px solid #ccf;
        }

        &.drag-target-below {
          border-bottom: 15px solid #ccf;
        }

        &.drag-target {
          background-color: #ccf;
        }

        &.otp-warning {
          border-color: $otp-warning;
        }

        &.otp-danger {
          border-color: $otp-danger;
        }


      }

      .lt-cell {
        padding: 0 4px 1px 1px; // same here: we just want the top cell to have padding

        &:focus-within {
          background-color: $table-cell-selected-color;
        }

        .lt-cell {
          padding: 0 0 0 0;
        }
      }

      .lt-body-wrap {
        margin-top: 3px;
      }

      .lt-body {
        color: $table-body-text;
        font-weight: 500;
        margin-top: 3px;
      }

      .is-sorted {
        .lt-sort-icon {
          visibility: visible;
        }
      }

      .lt-sort-icon {
        float: none;
        margin-left: auto;
        margin-right: 0;
        padding-left: 2px;
        // visibility: hidden;
        line-height: 20px;
      }
    }

    .column-widget-editable-cell {
      width: 100%;
    }

    .cell-text-extension {
       background-color: transparent;
       border: 0;
       padding: 0;
       color: #657175;
       font-weight: 300;
       text-overflow: ellipsis;
       white-space: nowrap;
    }
  }

  .this-trigger {
    position: absolute;
  }

  .loading-spinner {
    margin: auto;
  }

  .checkbox_row_heighlighted{
    background-color: yellow;
  }
}
