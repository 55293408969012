.analytics-widget {
  display: flex;
  flex-direction: column;
  background-color: $sidedrawer-table-background-color;

  .analytics-widget-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 2px 4px 2px;
    background-color: white;
    box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
    flex-shrink: 0;
    flex-basis: $widget-header-height;
    z-index: 5;

    .analytics-widget-title {
      flex-grow: 1;
      flex-shrink: 0;
      line-height: $widget-header-height - (2 * $widget-header-padding);
      margin: 0 0 0 $column-title-margin;
      padding-left: 10px;
      font-weight: 600;
      font-size: $widget-title-font-size;
    }

    // h4 {
    //   font-size: $widget-title-font-size;
    //   line-height: 30px;
    //   margin: 0 0 10px 4px;
    //   padding-left: 10px;
    //   padding-bottom: 5px;
    //   font-weight: 500;
    // }

    .analytics-widget-button {
      display: inline-block;
      padding: 0px 8px 8px 8px;
      border: none;
      color: $icon-light-color;
      background-color: transparent;
      opacity: 0.5;
      cursor: pointer;
      outline: none;

      &:hover {
        color: $icon-light-highlighted-color;
        transition: color 0.2s;
        opacity: 1;
      }
    }
  }

  .analytics-widget-body {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin: auto 0;
  }
}
