.ember-modal-dialog {
  table {
   // border: 2px solid $input-border-color;
    //border-radius: 3px;
    border-spacing: 0;
    border-collapse: separate;
    min-width: 200px;
    width: 100%;
    vertical-align: middle;
  }

  .ember-power-select-trigger {
    @include edit-input();
    span {
      margin-left: 0px;
    }
  }
  input:not([type="checkbox"]) {
    @include edit-input();

    &:disabled {
      background-color: transparent;
    }
  }

  input[readonly] {
    background-color: transparent;
  }

  .btn-toolbar {
    .btn {
      line-height: 1.1;
      padding: .375rem 0.55rem;
      font-size: $widget-button-font-size;

      &.btn-outline-primary {
        color: $form-widget-button-color;
        &:hover {
          background-color: $form-widget-button-color;
          color: white;
        }
      }

      &.btn-outline-secondary {
        color: $form-widget-button-color;

        &:hover {
          background-color: $form-widget-button-color;
          color: white;
        }
      }

      &:disabled {
        color: $icon-light-color;
        border-color: $button-outline;
        background-color: $button-disabled-background;
        &:hover {
          color: $icon-light-color;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

}
