.edit-widget-modal {
  //TODO - rm from nav-height
  height: calc(100vh - 70px);
  border-radius: 0;
  box-shadow: 7px 5px 20px -10px rgba(0, 0, 0, .9);
  position: absolute;
  top: $nav-height;
  left: 0;
  min-width: 50%;
  max-width: 50%;
  padding: 0;

  background-color: $sidedrawer-background-color;
}

.edit-modal-resize-handle {
  width: 10px;
  height: 100%;
  position: absolute;
  right: -5px;
  top: 0;
  cursor: ew-resize;
}

//ember-modal-dialog tranluscent can not otheriwse be overidden
.edit-widget-overlay {
  background-color: initial !important
}
