.modal-header {
  min-width: 500px;
  margin-top: 15px;
  padding-bottom:4px;
  .x-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  h6 {
    font-size: 13px;
  }

  label {
    font-size: 11px;
  }
}
