.timeline-relative-time-picker {
  white-space: nowrap;

  &.is-valid {
    background-color: rgba(darkseagreen, 0.2);
  }

  &.is-invalid {
    background-color: rgba(lightcoral, 0.2);
  }

  &.is-dirty {
    font-weight: bold;
  }

  .timeline-relative-time-picker-input {
    width: 70%;

    background-color: inherit;
    font-weight: inherit
  }

}
