.map-widget {
  $column-title-margin: 6px;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: $widget-border-width 0 $widget-border-width $widget-border-width;
  color: $text;
  font-family: $ui-font;

  leaflet-interactive {
    stroke :"#000000";
    stroke-opacity:"0.8";
    stroke-width: "6";
 }

 .leaflet-tooltip {
   position: absolute;
   padding: 6px;
   /* background-color: #fff; */
   /* border: 1px solid #fff; */
   border-radius: 3px;
   color: #000;
   white-space: nowrap;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   pointer-events: none;
   /* box-shadow: 0 1px 3px rgba(0,0,0,0.4); */
}

  .map-widget-header {
    flex-basis: $widget-header-height;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $widget-header-padding;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;

    background-color: $table-header-background-color;

    .map-widget-title {
      flex-grow: 1;
      flex-shrink: 0;
      line-height: $widget-header-height - (2 * $widget-header-padding);
      margin: 0 0 0 $column-title-margin;
      padding-left: 7px;
      font-weight: 600;
      font-size: $widget-title-font-size;
    }

    // the search box needs a little extra margin for focus border
    .map-widget-search-box {
      flex-grow: 1;
      flex-shrink: 0;
      height: $widget-header-height - (2 * $widget-header-padding) - 2px;
      margin: 1px 2px 1px $column-title-margin;
      padding: 3px 5px 3px 5px;
      max-width: 20em;
      border-radius: 5px;
      font-size: $widget-input-font-size;
      background-color: transparent;
      border: none;
      outline: none;
    }

    .map-widget-controls {
      flex-shrink: 0;
      height: 26px;
      margin-right: $column-title-margin;
      margin-left: auto; // helpful if we need to change .column-widget-header's justify-content attribute
      cursor: default;

      .map-widget-button {
        margin-top: 2px;
        padding: 1px 8px 0 8px;
        display: inline-block;
        background-color: inherit;
        border: none;
        color: $widget-button-color;
        opacity: 0.5;
        cursor: pointer;
        outline: none;

        &:hover {
          color: $text;
          transition: color 0.2s;
          opacity: 1;
        }

        &.disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            color: $widget-button-color;
            transition: none;
            opacity: 0.2;
          }
        }

        &:focus {
          box-shadow: 0 0 1px 1px $table-cell-selected-color;
          border-radius: 4px;
          opacity: 1;;
        }
      }
      .active-button {
        color: $text;
        transition: color 0.2s;
      }
    }
  }

  .map-widget-body {
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - #{$widget-border-width});
    height: 100%;
    overflow-x: auto;
  }

  .tomtom-map {
    margin-top: $widget-border-width;
    margin-left: $widget-border-width;
    width: calc(100% - #{2 * $widget-border-width});
    height: calc(100% - #{2 * $widget-border-width});
  }

  // prevent "invisible" trigger from taking up space in layout
  & > .ember-basic-dropdown-trigger {
    display: none;
  }
}
