@mixin font-face($name) {
  @font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNext-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNext-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
   }
  @font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNext-DemiBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNext-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
}

@include font-face('AvenirNext');
