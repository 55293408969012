.form-widget {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0px;
  background: #F5F5F7;
  padding: $widget-border-width 0 $widget-border-width $widget-border-width;
 

  color: $sidedrawer-font-color;
  font-family: $ui-font;

  display: flex;
  flex-direction: column;

  button {
    outline: none;

    &:focus {
      box-shadow: 0 0 0 0.2rem $primary;
    }
  }
  br {
    content: "";
    margin: 2em;
    display: block;
    font-size: 10%;
  }

  .ember-power-select-trigger:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  .flatpickr-input:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  .button-cell{
    padding: 2px;
    text-align: right;
    width: 20px;
    height: 20px;
  }

  .form-button-primary {
    font-size: 14px;
    font-weight: 500;
    border: 2px #7CB342 solid;
    background-color: #7CB342;
    color: white;
    border-radius: 5px;
    padding: 5px 25px 3px 25px;
    &:hover {
      background-color: $dds-green2;
      color: white;
    }
  }

  .form-button-outline {
    font-size: 14px;
    font-weight: 500;
    color: #7CB342;
    border: 2px #7CB342 solid;
    border-radius: 5px;
    padding: 5px 25px 3px 25px;

    &:hover {
      background-color: $dds-green2;
      color: white;
    }
  }

  .form-widget-header-wrapper {
    width: 100%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .5);
    z-index: 5;

    .form-widget-header {
      padding: 0 13px;
      margin: 0;
      background-color: $sidedrawer-table-background-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .form-widget-title {
        margin: auto 0;
        font-size: $sidedrawer-title-font-size;
        font-weight: 600;
      }

      .form-widget-search {
        font-size: $widget-body-font-size;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        border-radius: 3px;
        outline: none;
        display: flex;
        margin-right: 3px;

        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 16px;

        .form-widget-search-input {
          font-size: 18px;
          height: 100%;
          flex-grow: 1;
          flex-shrink: 1;
          border: none;

          &::placeholder {
            font-style: italic;
          }

          span {
            margin-left: 0px;
          }
        }

        .form-widget-search-x {
          position: relative;
          padding: 0 2px;
          top: 1px;
          float: right;
          background-color: transparent;
          border: none;
          color: $form-widget-button-color;
          font-size: 1.3em;

          &:hover {
            color: $icon-light-highlighted-color;
          }
        }
      }

      .form-widget-controls {
        flex-grow: 0;
        flex-shrink: 0;
        line-height: 1.1;
        cursor: default;

        .form-widget-header-button {
          padding: 8px 0;
          margin-left: 12px;
          background-color: transparent;
          color: $form-widget-button-color;
          border: none;
          cursor: pointer;

          &:hover {
            transition: color 0.2;
            color: $primary-button;
          }


          i {
            font-size: 1em;
          }

          .fa-check {
            font-size: 1.1em;
          }
          .fa-times {
            font-size: 1.1em;
          }

          .search-button {
            // padding-top: 6px;
          }
        }

        input {

        }


      }

    }


    .form-widget-subheader {
      margin: 5px 13px;

      .form-widget-readonly-section {
        .readonly-label {
          display: block;
          padding-right: 10px;
          white-space: nowrap;
          font-size: 16px;
          font-weight: 600;

          span {
            vertical-align: middle;
          }
        }
      }

      input {
        vertical-align: middle;
        margin-right: 2px;
      }

      .disabled {
        label {
          color: #BEC1C3;
        }

        input[type='checkbox'] {
          color: #AEB2B5;
          background: black;
        }
      }

      .subheader-buttons {
        display: inline;
        position:relative;
        top: -3px;
        float: right;

        button {
          font-size: 14px;
          font-weight: 500;
          border: 2px $primary-button solid;
          border-radius: 5px;
          padding: 5px 25px 3px 25px;
        }

        .header-button {
          background-color: $primary-button;
          color: white;

          &:hover {
            background-color: $dds-green2;
          }
        }

        .header-button-outline {
          color: $primary-button;

          &:hover {
            background-color: $dds-green2;
            color: $header-dark-background;
          }
        }
      }
    }
  }


  .form-widget-body {
    overflow: hidden;
    overflow-y: auto;
    flex: 1 1 auto;
    padding: 0px 12px 10px 12px;

    .subscriptions-table {
      border: none;
    }

    .section-table {
      border: 1px solid $sidedrawer-table-border-color;
      border-radius: 3px;
      border-spacing: 0;
      border-collapse: separate;
      min-width: 360px;
      width: 100%;
      vertical-align: middle;
      background-color: $sidedrawer-table-background-color;
      margin: 10px 0 0 0 !important;
      padding: 0 5px 5px 0;

      tr {
        &.header {
          user-select: none;
          cursor: pointer;
          vertical-align: middle;

          .fa-sort-down {
            transform: rotate(0deg);
            transition: transform .1s linear;
            width: 1.2rem;
            height: 0.5rem;

            &:before {
              position: relative;
              top: -.23rem;
              left: 0.6rem;
            }
          }

          &.is-closed {
            .fa-sort-down {
              transform: rotate(-90deg);
              top: 0.4rem;
              left: 0.2rem;
            }
          }
        }
      }
    }
  }

  .btn {
    color: $sidedrawer-button-color;
    border-color: $sidedrawer-button-outline;
    float: right;
  }

  .btn-remove {
    border: none;
    border-color: transparent;
    background-color: transparent;
    float: right;
  }

  &.passenger-form-widget table {
    min-width: 99%;
  }

  &.trip-form-widget table {
    min-width: 99%;
  }

  &.search-trip-form-widget table {
    min-width: 99%;
  }

  .row-hidden {
    display:none;
  }
  .cell-header {
    h4 {
      font-size: 16px;
      font-weight: 600;
      padding: 2px 5px 2px 5px;
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }

  .edit-cell {
    width: 100%;
    min-width: 100px;
    font-size: $widget-body-font-size;
    padding: 2px 0 2px 10px;

    .form-components-datetimeflatpickr {
      width: 100%;
    }
  }

  .cell-label {
    font-size: $widget-input-font-size;
    line-height: 1em;
    height: 100%;
    padding: 1px 0 1px 10px;
    text-align: left;
    vertical-align: middle;
    min-width: 105px;
  }

  .btn {
    // line-height: 0.5em;
    font-size: $widget-button-font-size;

    &.btn-outline-primary {
      color: white;
      background-color: $dds-green1;
      &:hover {
        color: white;
      }
    }

    &.btn-outline-secondary {
      color: $icon-light-highlighted-color;

      &:hover {
        color: white;
        background-color: $dds-green2;
      }
    }

    &.multitype-button {
        padding: 2px 5px;
      }


    &:disabled {
      color: $icon-light-color;
      border-color: $button-outline;
      background-color: $button-disabled-background;
      &:hover {
        color: $icon-light-color;
      }
    }
  }


  table {
    tr.header {
      user-select: none;
      cursor: pointer;
      vertical-align: middle;

      &.is-closed {
        .fa-sort-down {
          transform: rotate(-90deg);
          position: relative;
          left: 6px;
          bottom: 4px;
        }
      }
    }
  }
}
