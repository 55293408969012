.dummy-widget {
  background-color: #c05454;
  color: rgb(33 , 33, 33);

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;

  &.red {
    box-shadow: 0px 0px 0px 2px rgb(150, 50, 50) inset;
    background-color: rgb(255, 200, 200);
    color: rgb(50, 0, 0);
  }

  &.blue {
    box-shadow: 0px 0px 0px 2px rgb(50, 50, 150) inset;
    background-color: rgb(200, 200, 255);
    color: rgb(0, 0, 50);
  }

  &.green {
    box-shadow: 0px 0px 0px 2px rgb(50, 150, 50) inset;
    background-color: rgb(200, 255, 200);
    color: rgb(0, 50, 0)
  }

  .close-widget {
    position: absolute;
    right: 5px;
    top: 0;
    color: black;
  }
}
