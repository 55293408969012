.header-wrapper {
  line-height: $nav-height;
  height: $nav-height;
  padding: 0 20px;

  &.is-reordering {
    background-color: #2874CB !important;
  }

  /* box-shadow: 0px 0px 10px 0px black; */
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .5);
  z-index: 1;

  &.is-light {
    background: $header-light-background;
    color: black;
  }

  &.is-dark {
    background: $header-dark-background;
    color: white;
  }

  #header-center {
    z-index: 999;
    span {
      padding-right: 2em;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      &.btn-outline-primary {
        margin-right: 1em;
        &:focus {
          box-shadow: none;
        }

        &:active {
          background-color: $alt-primary-color;
          color: white;
          border-color: $alt-primary-color;
        }
      }
    }

    .reorder-stop {
      text-align: center;
      color: white;
      font-size: larger;
      // font-weight: 600;
      width: 50%;
      margin: 0 auto;
    }
  }
  #header-left {
    z-index: 999;
    span {
      padding-right: 2em;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      &.btn-outline-primary {
        margin-right: 1em;
        &:focus {
          box-shadow: none;
        }

        &:active {
          background-color: $alt-primary-color;
          color: white;
          border-color: $alt-primary-color;
        }
      }
    }

    .reorder-stop {
      text-align: center;
      color: white;
      font-size: larger;
      // font-weight: 600;
      width: 50%;
      margin: 0 auto;
    }
  }

  #header-right {
    > div {
      &:hover {
        .svg-header-icon {
          background-color: $icon-dark-highlighted-color;
          transition: background-color 0.2s;
        }
      }
    }
    max-width: 200px;
    i {
      font-size: 1.2rem;
      color: white;
      &:hover {
        color: $icon-dark-highlighted-color;
        transition: color 0.2s;
        cursor: pointer;
      }
    }

    .svg-header-icon {
      position: relative;
      top: -2px;
      width: 19px;
      height: 20px;
      mask: url(/timeline.svg);
      -webkit-mask: url(/timeline.svg) no-repeat center center;
      -webkit-mask-size: 97% 95%;
      background-color: #fff;

      cursor: pointer;
    }

    .workspace-icon {
      // icon is centered vertically by parent's line-height, but sits 1px high
      position: relative;
      top: 1px;
    }

    .plus-icon {
      // icon is centered vertically by parent's line-height, but sits 1px high
      position: relative;
      top: 1px;
    }
  }
}

.header-action-button {
  // button should sit vertically _inside_ header bar with top/bottom margins
  height: 20px;
  line-height: 20px;
  margin: 10px 0;

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px $table-cell-selected-color;
    border-radius: 3px;
  }
}

.header-nav-dropdown-content {
  // accounts for `margin-bottom` on `.header-action-button`
  margin-top: 10px;

  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, .2);
  font-family: $ui-font;
  font-size: $widget-body-font-size;
  background-color: rgba(38, 50, 56, 1);
  color: white;

  div {
    padding: 12px 20px;

    &:hover {
      cursor: pointer;
      background-color: $alt-primary-color;
      color: white;
      opacity: 1;
      transition: color 0.2s;
    }

    &[disabled] {
      background-color: $button-disabled-background;
      color: $grey;
    }
  }

  i {
    margin-right: 6px;
  }
}

.logo-image {
  background-image: url(/dds-logo.png);
  height: 60%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  top: 40%;
  transform: translateY(-50%);
  position: absolute;
}


textarea.text-info-multi {
    color: white;
    background: #212e35;
    width: 450px;
    overflow: auto;
    border-radius: 4px;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
    vertical-align: inherit;
    border-color: transparent;
    max-height: 250px;
    border-radius: 4px;
}
