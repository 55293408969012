// must load these before `ember-bootstrap/bootstrap`
@import 'bootstrap-overrides';

// vendor
@import 'ember-bootstrap/bootstrap';
@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";
@import 'ember-power-calendar';
@import 'ember-power-select';

// global
@import 'constants.scss';
@import 'mixins.scss';
@import 'fonts.scss';
@import 'font-styles.scss';

// general components
@import 'components/modal-sidebar.scss';
@import 'components/header-nav.scss';
@import 'components/edit-sidebar.scss';
@import 'components/maximized-widget.scss';
@import 'components/tile-dashboard.scss';
@import 'components/checkbox-switch.scss';
@import 'components/global-spinner.scss';
@import 'components/datepickr-row.scss';
@import 'components/form-components/text.scss';
@import 'components/form-components/ember-power-select.scss';
@import 'components/form-components/multi-select.scss';
@import 'components/form-components/radio.scss';
@import 'components/form-components/select-table.scss';
@import 'components/form-components/places.scss';
@import 'components/form-components/locations.scss';
@import 'components/form-components/travel-need.scss';
@import 'components/form-components/eligibilities.scss';
@import 'components/form-components/availabilities.scss';
@import 'components/form-components/subscriptions.scss';
@import 'components/buttons.scss';
@import 'components/checkbox.scss';

@import 'components/secondary-window.scss';
@import 'components/dashboard-picker.scss';
@import 'components/dashboard-save-as.scss';

@import 'components/timeline-picker.scss';
@import 'components/timeline/date-picker.scss';
@import 'components/timeline/time-picker.scss';
@import 'components/timeline/relative-time-picker.scss';

// side drawer components
@import 'components/side-drawer-modal.scss';
@import 'components/side-drawer.scss';
@import 'components/side-drawer/filter-settings.scss';
@import 'components/side-drawer/add-widget.scss';
@import 'components/side-drawer/map-legend-filter.scss';
@import 'components/side-drawer/route-activity-log.scss';
@import 'components/side-drawer/trip-activity-log.scss';
@import 'components/side-drawer/map-filter.scss';
@import 'components/side-drawer/metrics-filter.scss';


// widget components
@import 'components/widgets/base-widget.scss';
@import 'components/widgets/dummy-widget.scss';
@import 'components/widgets/column-widget.scss';
@import 'components/widgets/form-widget.scss';
@import 'components/widgets/map-widget.scss';
@import 'components/widgets/leaflet.scss';
@import 'components/widgets/box-widget.scss';
@import 'components/widgets/analytics-widget.scss';
@import 'components/widgets/metrics-column-widget.scss';
@import 'components/widgets/donut-chart-widget.scss';
@import 'components/widgets/bar-chart-widget.scss';
@import 'components/widgets/gauge-widget.scss';
@import 'components/widgets/map-widget-icons.scss';
@import 'components/widgets/tree-widget.scss';

// light-table components
@import 'components/table/cells/base-cell.scss';
@import 'components/table/cells/check-box.scss';
@import 'components/table/cells/user-editable.scss';
@import 'components/table/cells/user-editable-enum.scss';
@import 'components/table/cells/text-extension.scss';

@import 'components/table/columns/base-column.scss';
@import 'components/table/columns/check-box.scss';
@import 'components/table/columns/filter-capable.scss';

@import 'components/table/rows/base-row.scss';
@import 'components/table/rows/action-row.scss';

// routes
@import 'routes/application.scss';
@import 'routes/dashboard.scss';
@import 'routes/register.scss';
@import 'routes/login.scss';
@import 'routes/modals/settings.scss';
@import 'routes/modals/edit-widget-modal.scss';
@import 'routes/modals/text-extension-modal.scss';
@import 'routes/forgot.scss';
@import 'routes/reset.scss';

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

// Ember does not provide a way to place a class on the application view,
// so this ensures that sub-view can set `height: 100%` correctly
body > .ember-view {
  height: 100%;
}
