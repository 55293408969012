#forgot-password-template {
  margin: 0 auto;
  width: 35%;
  font-family: $ui-font;

  h3 {
    margin-top: 100px;
  }

  form {
    padding-top: 1rem;
    border-top: 1px solid rgb(238, 238, 238);
    text-align: right;

    label {
      width: 100%;
      text-align: left;

      input[type=text],
      input[type=email] {
        display: block;
        width: 100%;
        padding: 3px 7px;
        line-height: 1.5em;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        border-radius: 3px;
        outline: none;
      }

      input[disabled] {
        background: #EFEFEF;
      }
    }

    .btn {
      line-height: 1.1;
      padding: .375rem 0.55rem;
      font-size: 15px;
      background-color: white;
      border: 1px solid $button-outline;
      margin-left: .5rem;
      color: $icon-dark-highlighted-color;

      &:disabled {
        color: $button-outline;
        border: 1px solid $button-outline;
        &:hover {
          background-color: white;
          border: 1px solid $button-outline;
          color: $button-outline;
        }
      }

      &:hover {
        background-color: $icon-dark-highlighted-color;
        color: $icon-dark-color;
        border: 1px solid white;
      }
    }

    .errors {
      color: $icon-dark-color;
      background-color: #ec7272;
      border-radius: 3px;
      margin: 1em;
      padding: 5px 3px;
      list-style: none;
      text-align: center;
    }

    .success {
      color: $icon-dark-color;
      background-color: #81c784;
      border-radius: 3px;
      margin: 1em 0;
      padding: 3px 3px;
      /* list-style: none; */
      text-align: center;
      line-height: 1.5em;
      p {
        margin: 0;
      }
    }
  }
}
