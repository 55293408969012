.leaflet-tooltip {
  position: absolute;
  padding: 15px !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 3px;
  color: #000;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
   box-shadow: none !important;
}

.myJobcluster {
  background: #A48110; /* color of the circle */
  border-radius: 50%; /* make the div a circular shape */
  width: 30px  !important;
  height: 30px !important;
  text-align: center;
  font-size: 16px;
  font-weight: 1000;
  color: white;
  opacity: 0.5 !important;
  position: absolute;
}
.myVehiclecluster {
  background: #066B1B; /* color of the circle */
  border-radius: 50%; /* make the div a circular shape */
  width: 30px  !important;
  height: 30px !important;  
  text-align: center;
  font-size: 16px;
  font-weight: 1000;
  color: white;
  opacity: 0.5 !important;
  position: absolute;
}